<template>
  <div >
    <v-app-bar app fixed clipped-left height="80" elevation="10">
      <!--<v-app-bar-nav-icon @click.stop="$emit('toggle-drawer')">
        <v-icon>menu</v-icon>
      </v-app-bar-nav-icon>-->

      <v-avatar tile>
        <router-link to="/">
          <v-img
            lazy-src=""
            max-height="48"
            max-width="48"
            v-if="darkMode == true"
            :src="require(`@/assets/images/logo_transparent.png`)"
          ></v-img>
          <v-img
            lazy-src=""
            max-height="48"
            max-width="48"
            v-else
            :src="require(`@/assets/images/logo_white.png`)"
          ></v-img>
        </router-link>
      </v-avatar>

      <v-toolbar-title v-if="!$vuetify.breakpoint.xs" class="mx-5">{{ $route.name }}</v-toolbar-title>
      <v-col v-else cols="2"></v-col>

      <v-spacer></v-spacer>
      <div :width="$vuetify.breakpoint.xs?50:100">
        <v-btn
          color="button"
          class="button_color--text mr-5"
          to="/task/creation?code=DEVIS"
          :x-small="$vuetify.breakpoint.xs"
        >
          Demander un devis
        </v-btn>
        
        <v-btn
          color="button"
          class="button_color--text"
          to="/page/006100f7-676c-41f9-8df0-13fab55827ed"
          :x-small="$vuetify.breakpoint.xs"
        >
          Une question ?
        </v-btn>
      </div>
      <!--<v-form
        @submit.prevent="submitSearch"
        class="pa-0 ma-0"
        style="display: inline-flex"
      >
        <v-combobox
          v-model="searchSelect"
          :items="searchItems"
          @input="limiter"
          :menu-props="menuProps"
          @keyup.enter="submitSearch"
          autofocus
          multiple
          return-object
          prefix="Rechercher un contrat ou un devis "
          clearable
          open-on-clear
          small-chips
          deletable-chips
          solo
          outlined
          dense
          hide-details
          hide-no-data
          hide-selected
          append-icon=""
          class="shrink mx-2"
          :disabled="disabledCombobox"
        >
          <template v-slot:selection="{ attrs, index, item, parent, selected }">
            <v-chip
              v-if="index == 0"
              v-bind="attrs"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">
                {{ item.text }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>

            <span class="pr-2" v-else>{{ item }}</span>
          </template>
        </v-combobox>

        <v-btn
          color="button"
          class="button_color--text"
          :disabled="disabledCombobox"
          type="submit"
        >
          <v-icon left>search</v-icon>Rechercher
        </v-btn>
      </v-form>-->

      <AccountMenu v-on:childToParent="onChildClick"></AccountMenu>
    </v-app-bar>
  </div>
</template>

<style scoped>
/* Add colored border bottom to top app bar */
::v-deep .v-toolbar__content {
  border-bottom: 4px solid var(--v-app_bar_bottom-base);
}
</style>

<script>
import AccountMenu from "@/components/layout/common/AccountMenu";

import axios from "axios";
import { mapState, mapActions } from "vuex";
export default {
  name: "AppBar",
  components: {
    AccountMenu,
  },
  data: () => ({
    appDir: process.env.VUE_APP_NAME,
    darkMode: false,
    profilMenu: true,

    user: {
      id: "",
      firstName: "",
      lastName: "",
      userName: "",
      initials: "",
      email: "",
      isEnabled: false,
      userBrokerId: null,
    },

    searchSelect: [{ id: "searchNumber", text: "par numéro" }],
    searchItems: [
      { id: "searchNumber", text: "par numéro" },
      { id: "lastName", text: "par nom" },
    ],
    menuProps: {
      disabled: true,
    },
    disabledCombobox: false,
  }),
  async created() {
    this.$store.dispatch("getAgency");
  },
  mounted() {},
  computed: {
    switchDarkModeLabel: function () {
      return this.darkMode ? "Light" : "Dark";
    },
    initials() {
      return this.$store.state.auth.data.user.initials;
    },
    userName() {
      return this.$store.state.auth.data.user.userName;
    },
  },
  methods: {
    onChildClick(value) {
      this.darkMode = value;
    },
    submitSearch() {
      if (this.$route.name != "Recherche de devis et contrats") {
        if (this.searchSelect[0] && this.searchSelect[1]) {
          this.$router.push(
            "/contract/topSearch?" +
              this.searchSelect[0].id +
              "=" +
              this.searchSelect[1] +
              ""
          );
        }
      }
    },
    limiter(e) {
      // console.log(e[0]);
      // console.log('limiter');

      this.menuProps.disabled = false;

      if (e.length > 0) {
        this.menuProps.disabled = true;
        // console.log('type already selected '+e[0])

        if (e[0].text != "par numéro" && e[0].text != "par nom") {
          // console.log('you have to select a type first')
          this.menuProps.disabled = false;
          e.pop();
        }
        // e.pop()
      }
      if (e.length > 2) {
        this.menuProps.disabled = true;
        // console.log('you can only select on search input text')
        e.pop();
      }
    },
    toggleDarkMode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
    },
    logout() {
      // this.$router.push( {path: '/logout'} )
      window.location.href = "/logout";
    },
    // userGetUsername(event) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get("/api/profile")
    //       .then((response) => {
    //         // Success 🎉
    //         // console.log(response.data);
    //         // this.$store.commit("getUser", response.data.user);
    //         const data = response.data.user.userBrokerCode;
    //         resolve({
    //           data,
    //         });
    //       })
    //       .catch((error) => {
    //         // Error 😨
    //         if (error.response) {
    //           /*
    //            * The request was made and the server responded with a
    //            * status code that falls out of the range of 2xx
    //            */
    //           console.log("error.response");
    //           console.log(error.response.data);
    //           console.log(error.response.status);
    //           console.log(error.response.headers);
    //         } else if (error.request) {
    //           /*
    //            * The request was made but no response was received, `error.request`
    //            * is an instance of XMLHttpRequest in the browser and an instance
    //            * of http.ClientRequest in Node.js
    //            */
    //           console.log("error.request");
    //           console.log(error.request);
    //         } else {
    //           // Something happened in setting up the request and triggered an Error
    //           console.log("Error");
    //           console.log("Error", error.message);
    //         }
    //         console.log("error.config");
    //         console.log(error.config);
    //       });
    //   });
    // },
  },
  watch: {
    "$route.path": {
      handler() {
        if (
          this.$route.path == "/contract/topSearch" ||
          this.$route.path == "/contract/search"
        ) {
          this.disabledCombobox = true;
        } else {
          this.disabledCombobox = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
