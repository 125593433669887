import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6Lfbt8EcAAAAAHNQD-y0N-mfBx-EhuOZJWbNJgll" });

import './utils/logger-init.js';

// import tiptap lugin
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";
// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "md",
});

Vue.config.productionTip = false;

Vue.prototype.$appName = process.env.VUE_APP_NAME;
Vue.prototype.$systempay_baseurl = process.env.VUE_APP_SYSTEMPAY_URL;
Vue.prototype.$systempay_pubkey = process.env.VUE_APP_SYSTEMPAY_PUBKEY;
Vue.prototype.$currency = process.env.VUE_APP_CURRENCY;
const ibanStartsWith = process.env.VUE_APP_IBAN_STARTS_WITH;
if (ibanStartsWith && ibanStartsWith.trim() !== '') {
  Vue.prototype.$ibanStartsWith = ibanStartsWith.split(',').map(value => value.trim());
}
Vue.prototype.$codeAuto = process.env.VUE_APP_OKAYO_PRODUCT_CODE_AUTO;
Vue.prototype.$codeGav = process.env.VUE_APP_OKAYO_PRODUCT_CODE_GAV;
Vue.prototype.$codeMrhEtu = process.env.VUE_APP_OKAYO_PRODUCT_CODE_MRH_ETU;
Vue.prototype.$codeAutoKm = process.env.VUE_APP_OKAYO_PRODUCT_CODE_AUTO_KM;
Vue.prototype.$codeMrhBudget = process.env.VUE_APP_OKAYO_PRODUCT_CODE_MRH_BUDJET;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
