<template lang="pug">
  v-row(justify='center')
    v-col(cols='12' sm='4')
      //v-skeleton-loader.mt-5(type='card-heading, list-item@1' height='150')
      v-card.pb-3(v-if="!requestCreated")
        v-card-title.justify-center
          v-img(lazy-src='' max-height='48' max-width='48' :src='require(`@/assets/images/logo_white.png`)')
        v-card-subtitle.mb-0.pb-0.pt-5.text-center
          span.text-h6 Récupérer mon mot de passe
          br
          | Merci de bien vouloir saisir votre identifiant
        validation-observer(ref='emailObserver' v-slot='{ invalid }')
          v-form(@submit.prevent='createRequest')
            v-card-text
              validation-provider(v-slot='{ errors }' vid='email' name='identifiant' rules='required|max:50')
                v-text-field(v-model='email' :error-messages='errors' label='Identifiant' required='')
            v-card-actions.text-right
              v-btn.button_color--text(:loading='loading' color='button' type='submit' :disabled='invalid')
                | réinitialiser mon mot de passe
      v-card.pb-3(v-if="requestCreated")
        v-card-title.justify-center
          v-img(lazy-src='' max-height='48' max-width='48' :src='require(`@/assets/images/logo_white.png`)')
        v-card-subtitle.mb-0.pb-0.pt-5.pb-7.text-center
          span.text-h6 Votre demande a bien été créée
          br
          | {{message.message }}
      //SnackBar(:show="showSnackbar" :message='message')
</template>

<script>
import { required, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";

localize("fr", fr);
setInteractionMode("eager");
extend("required", required);
extend("max", max);

import SnackBar from "@/components/common/SnackBar";
import ResetPasswordService from "@/services/reset-password.service";

export default {
  name: "CheckMyEmail",
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar,
  },
  data: () => ({
    appDir: process.env.VUE_APP_NAME,
    loading: false,
    showSnackbar: false,
    message: null,
    email: "",
    requestCreated: false,
  }),
  methods: {
    async createRequest() {
      this.showSnackbar = false;
      try {
        this.loading = true;
        let response = await ResetPasswordService.createRequest(this.email);
        this.message = {
          type: response.success ? "success" : "error",
          message: response.message,
        };
        if (response.success) {
          this.requestCreated = true;
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.message = {
          type: "error",
          message: error.response.data.message,
        };
        this.showSnackbar = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
