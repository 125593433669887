<template>
  <div>
    <!-- Tab titles -->
    <v-skeleton-loader v-show="loading" type="table-thead">
    </v-skeleton-loader>

    <div v-show="!loading">
      <v-card v-model="contract" class="mt-3">
        <v-card-title class="text-h6 text-uppercase tertiary list_color--text">
          <v-icon class="list_color--text" left>
            {{ contract.details.icon }}
          </v-icon>
          {{ contract.details.number }}
          <v-spacer></v-spacer>
          <div v-show="!loading">
            <v-menu open-on-hover offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" elevation="10" class="tertiary_light button_color--text" x-large>
                  Actions
                </v-btn>
              </template>

              <v-list dense no-action class="pt-0">
                <template v-if="!this.contract.details.isEstimate">
                  <v-list-item v-for="(action, i) in actionsContractMenu" :key="i" router :to="action.route"
                    @click="functionCall(action.action)">
                    <v-list-item-title class="font-weight-regular">{{
                      action.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
                <template v-else>
                  <v-list-item v-for="(action, i) in actionsInvoiceMenu" :key="i" router :to="action.route"
                    @click="functionCall(action.action)">
                    <v-list-item-title class="font-weight-regular">{{
                      action.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </div>
        </v-card-title>

        <v-card-text>

          <v-layout row="row" wrap="wrap">

            <v-flex lg4="lg4" md4="md4" sm12="sm12" xs12="xs12">

              <v-list shaped color="#e0e0e0" class="mt-8 mb-4 mr-3">

                <v-list-item to="#pieces">
                  <v-list-item-content>
                    <v-list-item-title :class="getTextClassRedOrBlue(contract.computed.processingDocumentsNb)">Pièces
                      justificatives à ajouter</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div :class="getCircleClassRedOrBlue(contract.computed.processingDocumentsNb)">
                      {{ contract.computed.processingDocumentsNb }}</div>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item to="#documents">
                  <v-list-item-content>
                    <v-list-item-title :class="getTextClassRedOrBlue(contract.computed.processingEditicsNb)">Documents à
                      signer</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div :class="getCircleClassRedOrBlue(contract.computed.processingEditicsNb)">
                      {{ contract.computed.processingEditicsNb }}</div>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item to="#sinistres">
                  <v-list-item-content>
                    <v-list-item-title :class="getTextClassRedOrBlue(contract.computed.claimsNb)">Sinistres en
                      cours</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div :class="getCircleClassRedOrBlue(contract.computed.claimsNb)">{{ contract.computed.claimsNb }}
                    </div>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item to="#primes">
                  <v-list-item-content>
                    <v-list-item-title :class="getTextClassRedOrBlue(contract.details.solde)">Solde à
                      régler</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <span :class="getTextClassRedOrBlue(contract.details.solde)">{{ contract.details.solde }} {{ $currency }}</span>
                  </v-list-item-action>
                </v-list-item>

              </v-list>
            </v-flex>

            <v-flex xs="12" sm="12" md="8" lg="8" align-self-center="true">
              <v-simple-table v-model="contract" class="mt-5">

                <template v-if="$vuetify.breakpoint.xsOnly" v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-md-right">Sociétaire</td>
                      <td v-if="contract.details.person.personType == 'PHY'" class="font-weight-bold"
                        v-text="contract.details.person.fullname"></td>
                      <td v-else class="font-weight-bold" v-text="contract.details.person.tradeName"></td>
                    </tr>

                    <tr>
                      <td class="text-md-right">Type</td>
                      <td class="font-weight-bold">{{ contract.details.type.libelle }}</td>
                    </tr>

                    <tr>
                      <td class="text-md-right">Produit</td>
                      <td class="font-weight-bold">{{ contract.details.product.libelle }}</td>
                    </tr>

                    <tr>
                      <td class="text-md-right">Statut</td>
                      <td class="font-weight-bold">{{ contract.details.status }}</td>
                    </tr>

                    <tr>
                      <td class="text-md-right">Date d'effet</td>
                      <td class="font-weight-bold">{{ new Date(contract.details.effectDate).toLocaleDateString() }}</td>
                    </tr>

                    <tr>
                      <td class="text-md-right">Solde TTC</td>
                      <td class="font-weight-bold">{{ contract.details.solde + ' ' + $currency }}</td>
                    </tr>


                    <tr>
                      <td class="text-md-right"><v-icon>email</v-icon></td>
                      <td class="font-weight-bold">{{ contract.details.addresses.mail.mail }}</td>
                    </tr>

                    <tr>
                      <td class="text-md-right"><v-icon>call</v-icon></td>
                      <td class="font-weight-bold">{{ contract.details.addresses.phone.number.match(/.{1,2}/g).join(' ') }}
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-else v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-md-right">Sociétaire</td>
                      <td v-if="contract.details.person.personType == 'PHY'" class="font-weight-bold"
                        v-text="contract.details.person.fullname"></td>
                      <td v-else class="font-weight-bold" v-text="contract.details.person.tradeName"></td>
                      <td class="text-md-right">Type</td>
                      <td class="font-weight-bold">{{contract.details.type.libelle}}</td>
                    </tr>

                    <tr>
                      <td class="text-md-right">Produit</td>
                      <td class="font-weight-bold">{{contract.details.product.libelle}}</td>
                      <td class="text-md-right">Statut</td>
                      <td class="font-weight-bold">{{contract.details.status}}</td>
                    </tr>

                    <tr>
                      <td class="text-md-right">Date d'effet</td>
                      <td class="font-weight-bold">{{new Date(contract.details.effectDate).toLocaleDateString()}}</td>
                      <td class="text-md-right">Solde TTC</td>
                      <td class="font-weight-bold">{{contract.details.solde + ' ' + $currency}}</td>
                    </tr>


                    <tr>
                      <td class="text-md-right"><v-icon>email</v-icon></td>
                      <td class="font-weight-bold">{{contract.details.addresses.mail.mail}}</td>
                      <td class="text-md-right"><v-icon>call</v-icon></td>
                      <td class="font-weight-bold">{{contract.details.addresses.phone.number.match(/.{1,2}/g).join(' ')}}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>

          </v-layout>

        </v-card-text>

      </v-card>
      <v-tabs v-model="tab" grow color="list_color" background-color="list_background" active-class="tertiary" center-active show-arrows>
        <v-tabs-slider color="tertiary"></v-tabs-slider>

        <v-tab v-for="tab in tabs" :key="tab.title" class="list_color--text" :to="tab.href">
          {{ tab.title }}
          <v-icon right small color="tertiary_light">
            {{ tab.icon }}
          </v-icon>
          <span class="tertiary_light--text">{{ tab.number }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" :value="tab">
        <!-- First tab item, General contract -->
        <v-tab-item value="informations">

          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <!-- General Informations -->
                <tr>
                  <td colspan="4" class="pa-0 pt-5">
                    <v-subheader class="text-h5">Informations générales</v-subheader>
                  </td>
                </tr>

                <tr>
                  <td class="text-right">Formule</td>
                  <td class="font-weight-bold" v-text="contract.details.formula.libelle"></td>

                  <td colspan="2" rowspan="3">
                    <template v-if="contract.details.annualPrime > 0 && contract.details.annualPrime">
                      <v-simple-table class="list_background_light pa-4">
                        <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-center" style="vertical-align: top">
                    <span class="text-h5">Prime annuelle TTC</span><br />
                    <span class="text-h4" v-text="contract.details.annualPrime + ' ' + $currency"></span>
                  </td>
                  <td class="text-right" style="border-left: 1px solid black" v-if="contract.computed.payementMethod">
                    <span class="text-h5">Prochain prélévement</span><br />
                    <span class="text-h4">de {{ contract.computed.nextTakingAmount + " " + $currency
                    }}<br />le {{ contract.computed.nextTakingDate }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          </template>
          </td>
          </tr>
          <!--<tr>
                  <td class="text-right">Formule</td>
                  <td class="font-weight-bold" v-text="contract.formula"></td>
                </tr>-->
          <tr>
            <td class="text-right">Fractionnement</td>
            <td class="font-weight-bold" v-text="contract.details.formula.splitting"></td>
          </tr>
          <tr>
            <td class="text-right">Mode de paiement</td>
            <td class="font-weight-bold" v-text="contract.computed.payementMethod"></td>
          </tr>

          <!-- Subscriber Informations -->
          <tr>
            <td colspan="4" class="pa-0 pt-5">
              <v-subheader class="text-h5">Informations souscripteur</v-subheader>
            </td>
          </tr>

          <template v-if="contract.details.person.personType == 'PHY'">
            <tr>
              <td class="text-right">Civilité</td>
              <td class="font-weight-bold" v-text="contract.details.person.civility.libelleLong"></td>
              <td colspan="2" rowspan="5">
                <div class="d-flex">
                  <v-icon left color="tertiary_light" size="64">location_on</v-icon>
                  <div class="text-h6">
                    {{ contract.details.addresses.address.address1 }}
                    <br />
                    {{ contract.details.addresses.address.zipCode }} {{ contract.details.addresses.address.city }}
                  </div>
                </div>

                <br />
                <br />

                <div class="d-flex" v-if="contract.details.person.bankAccount">
                  <v-icon left color="tertiary_light" size="64">payment</v-icon>
                  <div class="text-h6">
                    {{ contract.details.addresses.bankAccount.accountOwner }}
                    <br />
                    {{ contract.details.addresses.bankAccount.ibanCode }}
                    <br />
                    {{ contract.details.addresses.bankAccount.bicCode }} {{ contract.details.addresses.bankAccount.bicCode
                      != "" ? "/" : "" }} {{ contract.details.addresses.bankAccount.domiciliation }}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-right">Date de naissance</td>
              <td class="font-weight-bold" v-text="new Date(contract.details.person.birthDate).toLocaleDateString()"></td>
            </tr>
            <tr>
              <td class="text-right">Situation maritale</td>
              <td class="font-weight-bold" v-text="contract.details.person.maritalStatus.libelle"></td>
            </tr>
            <tr>
              <td class="text-right">Catégorie socioprofessionnelle</td>
              <td class="font-weight-bold" v-text="contract.details.person.socioProfessionalCategory.libelle"></td>
            </tr>
            <tr>
              <td class="text-right">Profession</td>
              <td class="font-weight-bold" v-text="contract.details.person.profession"></td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td class="text-right">Nom commercial</td>
              <td class="font-weight-bold" v-text="contract.details.person.tradeName"></td>
              <td colspan="2" rowspan="5">
                <div class="d-flex">
                  <v-icon left color="tertiary_light" size="64">location_on</v-icon>
                  <div class="text-h6">
                    {{ contract.details.addresses.address.address1 }}
                    <br />
                    {{ contract.details.addresses.address.zipCode }} {{ contract.details.addresses.address.city }}
                  </div>
                </div>

                <br />
                <br />

                <div class="d-flex" v-if="contract.details.person.bankAccount">
                  <v-icon left color="tertiary_light" size="64">payment</v-icon>
                  <div class="text-h6">
                    {{ contract.details.addresses.bankAccount.accountOwner }}
                    <br />
                    {{ contract.details.addresses.bankAccount.ibanCode }}
                    <br />
                    {{ contract.details.addresses.bankAccount.bicCode }} {{ contract.details.addresses.bankAccount.bicCode
                      != "" ? "/" : "" }} {{ contract.details.addresses.bankAccount.domiciliation }}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-right">Forme juridique</td>
              <td class="font-weight-bold" v-text="contract.details.person.legalStatus.libelle"></td>
            </tr>
            <tr>
              <td class="text-right">Code SIREN</td>
              <td class="font-weight-bold" v-text="contract.details.person.siren"></td>
            </tr>
            <tr>
              <td class="text-right">Effectif</td>
              <td class="font-weight-bold" v-text="contract.details.person.effective"></td>
            </tr>
            <tr>
              <td class="text-right">Site web</td>
              <td class="font-weight-bold" v-text="contract.details.person.website"></td>
            </tr>
          </template>

          <template
            v-if="contract.details.product.code == $codeAuto || contract.details.product.code == $codeAutoKm">
            <tr>
              <td colspan="4" class="pa-0 pt-5">
                <v-subheader class="text-h5">Informations sur le risque</v-subheader>
              </td>
            </tr>
            <tr>
              <td class="text-right">Immatriculation</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicleInfos.registration">
              </td>
              <td class="text-right">Date de 1ère mise en circulation</td>
              <td class="text-uppercase font-weight-bold"
                v-text="new Date(contract.details.productRisk.vehicleInfos.firstUseDate).toLocaleDateString()"></td>
            </tr>
            <tr>
              <td class="text-right">Marque</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicle.brand"></td>
              <td class="text-right">Date d'acquisition</td>
              <td class="text-uppercase font-weight-bold"
                v-text="new Date(contract.details.productRisk.vehicleInfos.acquisitionDate).toLocaleDateString()"></td>
            </tr>
            <tr>
              <td class="text-right">Modèle</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicle.model"></td>
              <td class="text-right">Usage</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicleInfos.use.code">
              </td>
            </tr>
            <tr>
              <td class="text-right">Version</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicle.version"></td>
              <td class="text-right">Nombre de Km par an</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicleInfos.kmsByYear">
              </td>
            </tr>
            <tr>
              <td class="text-right">Type Mines</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicle.minesType"></td>
              <td class="text-right">Mode de garage</td>
              <td class="text-uppercase font-weight-bold"
                v-text="contract.details.productRisk.vehicleInfos.parkDetails.type"></td>
            </tr>
            <tr>
              <td class="text-right">Code SRA</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicle.SRACode"></td>
              <td class="text-right">Code postal</td>
              <td class="text-uppercase font-weight-bold"
                v-text="contract.details.productRisk.vehicleInfos.parkDetails.zipCode"></td>
            </tr>
            <tr>
              <td class="text-right">Energie</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicle.energy"></td>
              <td class="text-right">Ville</td>
              <td class="font-weight-bold" v-text="contract.details.productRisk.vehicleInfos.parkDetails.city"></td>
            </tr>
            <tr>
              <td class="text-right">Puissance administrative</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicle.fiscalPower"></td>
              <td class="text-right">Zone</td>
              <td class="text-uppercase font-weight-bold"
                v-text="contract.details.productRisk.vehicleInfos.parkDetails.area"></td>
            </tr>
            <tr>
              <td class="text-right">Genre</td>
              <td class="text-uppercase font-weight-bold" v-text="contract.details.productRisk.vehicle.type"></td>
              <td></td>
              <td></td>
            </tr>
          </template>

          <!-- Driver Informations -->
          <template
            v-if="contract.details.product.code == $codeAuto || contract.details.product.code == $codeAutoKm">
            <tr>
              <td colspan="4" class="pa-0 pt-5">
                <v-subheader class="text-h5">Informations conducteur(s)</v-subheader>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nom</th>
                        <th class="text-left">Type</th>
                        <th class="text-left">CRM</th>
                        <th class="text-left">Date de naissance</th>
                        <th class="text-left">Date de permis</th>
                        <th class="text-left">Nombre d'année(s) Bonus 50</th>
                        <th class="text-left">Période assurance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(driver, index) in contract.details.productRisk.drivers" :key="index">
                        <td>{{ driver.person.fullname }}</td>
                        <td>{{ driver.isPrincipal == "true" ? "Principal" : "Secondaire" }}</td>
                        <td>{{ (driver.risk || {}).crm || null }}</td>
                        <td>{{ new Date(driver.person.birthDate).toLocaleDateString() }}</td>
                        <td>{{ new Date(driver.person.driverLicence.effectDate).toLocaleDateString() }}</td>
                        <td>{{ (driver.risk || {}).minCRMDuration || null }}</td>
                        <td>{{ (driver.risk.seniority || {}).code || null }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </tr>
          </template>

          <!-- Guarantees Informations -->
          <template v-if="contract.details.formula.guarantees.length">
            <tr>
              <td colspan="4" class="pa-0 pt-5">
                <v-subheader class="text-h5">Garantie(s) souscrite(s)</v-subheader>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Garantie</th>
                        <!-- <th class="text-right">TTC</th> -->
                        <th class="text-right">Franchise</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(guarantee, index) in contract.details.formula.guarantees" :key="index">
                        <td>{{ guarantee.libelle }}</td>
                        <!-- <td class="text-right">{{ guarantee.amount }} {{ $currency }}</td> -->
                        <td class="text-right" v-if="guarantee.franchiseValue > 0">
                          {{ guarantee.franchiseValue }}
                          {{ guarantee.franchiseCurrencylabel }}
                        </td>
                        <td v-else></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </tr>
          </template>

          <!-- Optional Guarantees -->
          <template
            v-if="contract.details.productRisk && contract.details.productRisk.options && (contract.details.productRisk.options.isConduiteExclusive || contract.details.productRisk.options.niveauAssistance)">
            <tr>
              <td colspan="4" class="pa-0 pt-5">
                <v-subheader class="text-h5">Garantie(s) optionnelle(s)</v-subheader>
              </td>
            </tr>
            <tr>
              <td class="text-right">Conduite exclusive</td>
              <td class="text-uppercase font-weight-bold"
                v-text="contract.details.productRisk.options.isConduiteExclusive"></td>
              <td class="text-right"></td>
              <td class="text-uppercase font-weight-bold"></td>
            </tr>
            <tr>
              <td class="text-right">Niveau d'assistance</td>
              <td class="text-uppercase font-weight-bold"
                v-text="contract.details.productRisk.options.niveauAssistance.code"></td>
              <td class="text-right"></td>
              <td class="text-uppercase font-weight-bold"></td>
            </tr>
          </template>
          </tbody>
          </template>
          </v-simple-table>
        </v-tab-item>


        <!-- Todo, Third tab item, Primes -->
        <v-tab-item v-if="!this.contract.isInvoice" value="primes">
          <PrimesDataTable :data="this.contract.primes.data" :headers="this.contract.primes.headers"
            :options="this.contract.primes.options" :contract="this.contract" :getContractParent="submitGetContract" />
        </v-tab-item>

        <!-- Todo, Third tab item, Documents -->
        <v-tab-item value="pieces">
          <PiecesDataTable :data="this.contract.documents.data" :headers="this.contract.documents.headers"
            :options="this.contract.documents.options" :contract="this.contract" :getContractParent="submitGetContract" />
        </v-tab-item>

        <!-- Todo, Third tab item, Claims/Sinistres -->
        <v-tab-item v-if="!this.contract.isInvoice" value="sinistres">
          <GenericDataTable v-if="!this.contract.isInvoice" :data="this.contract.claims.data"
            :headers="this.contract.claims.headers" :options="this.contract.claims.options" />
        </v-tab-item>

        <!-- Tab Editiques -->
        <v-tab-item value="documents">
          <EditicsDataTable :data="this.contract.editiques.data" :headers="this.contract.editiques.headers"
            :options="this.contract.editiques.options" :isEstimation="false" :contract="this.contract"
            :getContractParent="submitGetContract" />
        </v-tab-item>

      </v-tabs-items>
    </div>
    <!-- Side navigation -->
    <v-dialog id="modalModifMail" v-model="modalModifMail" width="600">
      <UpdateMailTel :contract="this.contract" :getContractParent="submitGetContract" v-on:close-dialog="closeDialog" />
    </v-dialog>

    <v-dialog id="modalModifPayment" v-model="modalModifPayment" width="600">
      <UpdateBankAddress :contract="this.contract" :getContractParent="submitGetContract" :isDialog="true"
        v-on:close-dialog="closeDialog" />
    </v-dialog>
  </div>
</template>

<style scoped>
#contractFixedHeader {
  position: sticky;
  top: 80px;
  z-index: 1;
}

/* #fab-menu .v-btn {
  position: fixed;
  top: 250px;
  right: 25px;
  z-index: 1;
} */

::v-deep tr:hover {
  background-color: transparent !important;
}

.row-pointer {
  cursor: pointer;
}

.row-pointer>tbody tr:hover {
  cursor: pointer;
  background-color: var(--v-list_background_light-base) !important;
}

.numberCircleRed {
  font-size: 18px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding-top: 14px;
  text-align: center;
  color: white;
  background-color: var(--v-error-base);
}

.numberCircleBlue {
  font-size: 18px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding-top: 14px;
  text-align: center;
  color: white;
  background-color: var(--v-tertiary_light-base);
}
</style>

<script>
import GenericDataTable from "../../components/GenericDatatable.vue";
import EditicsDataTable from "../../components/EditicsDataTable.vue";
import PiecesDataTable from "../../components/PiecesDataTable.vue";
import PrimesDataTable from "../../components/PrimesDataTable.vue";
import UpdateMailTel from "../../components/UpdateMailTel.vue";
import UpdateBankAddress from "../../components/UpdateBankAddress.vue";

import axios from "axios";

import Vue from 'vue';

export default {
  name: "Contract",

  components: {
    GenericDataTable,
    EditicsDataTable,
    PiecesDataTable,
    PrimesDataTable,
    UpdateMailTel,
    UpdateBankAddress,
  },

  data: () => ({
    slider: [],
    contracts: [],
    //options: {},


    idContrat: 0,

    tab: null,
    loading: false,
    /* contract: {
      guarantees: [],
      details: [],
      primes: [],
      movements: [],
      claims: [],
      tasks: [],
      documents: [],
      editiques: [],
    }, */



    modalModifMail: false,
    modalModifPayment: false,
  }),
  props: {
    contract: Object
  },
  computed: {

    actionsInvoiceMenu() {
      return [
        { title: "Ajouter des pièces", route: "#pieces" },
        { title: "Autre demande", route: `/task/creation?cid=${this.contract.details.id}` },
      ]
    },
    actionsContractMenu() {
      return [
        { title: "Ajouter des pièces", route: "#pieces" },
        {
          title: "Modifier le mail / téléphone",
          route: "",
          action: "showDialogModifMail",
        },
        { title: "Demande d'avenant", route: `/task/creation?code=AVENANT&cid=${this.contract.details.id}` },
        { title: "Autre demande", route: `/task/creation?cid=${this.contract.details.id}` },
        /* { title: "Modifier l'IBAN", route: "", action: "showDialogModifPayment" }, */
        { title: "Payer en ligne", route: "#primes" },
      ]
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
    tabs: function () {
      if (this.contract.isInvoice) {
        return [
          {
            title: "Informations",
            href: "#informations",
          },
          {
            title: "Pièces",
            href: "#pieces",
          },
          { title: "Documents", href: "#documents" },
        ];
      } else {
        return [
          { title: "Informations", href: "#informations" },
          {
            title: "Primes",
            icon: this.contract.computed.unpaidPrimes ? "priority_high" : "",
            href: "#primes",
          },
          {
            title: "Pièces",
            href: "#pieces",
          },
          {
            title: "Sinistres",
            href: "#sinistres",
          },
          { title: "Documents", href: "#documents" },
        ];
      }
    },
  },
  methods: {
    getCircleClassRedOrBlue(number) {
      console.log(number)
      return parseInt(number) > 0
        ? 'numberCircleRed'
        : 'numberCircleBlue'
    },
    getTextClassRedOrBlue(number) {
      console.log(number)
      return parseInt(number) > 0
        ? 'error--text font-weight-bold'
        : ''
    },
    showDialogModifMail() {
      this.modalModifMail = true;
    },
    showDialogModifPayment() {
      this.modalModifPayment = true;
    },
    closeDialog: function () {
      this.modalModifMail = false;
      this.modalModifPayment = false;
    },
    functionCall(name) {
      if (name) {
        this[name]();
      }
    },
    async submitGetContract(id = this.contract.details.id) {
      this.$set(this.contract, {});         /* //details: [],
        guarantees: [],
        primes: [],
        movements: [],
        claims: [],
        tasks: [],
        documents: [],
        editiques: [], */
      //};
      this.loading = true;
      this.getContract(id).then((data) => {
        this.contract = data.contract;
        this.loading = false;
        this.modalModifMail = false;
        this.modalModifPayment = false;
      });
    },
    getContract(id) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/contract/societaire/get", {
            idContrat: id,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let contract = response.data.contract;
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              contract,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
  },
};
</script>
