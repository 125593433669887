<template>
  <div>
    <v-stepper v-model="riskStep" vertical>
      <v-stepper-step :complete="riskStep > 1" editable step="1">
        Conditions Générales
      </v-stepper-step>

      <v-stepper-content step="1">
        <CG />
      </v-stepper-content>

      <v-stepper-step
        :complete="riskStep > 2"
        :editable="riskStep > 1"
        step="2"
      >
        Informations Véhicule
      </v-stepper-step>

      <v-stepper-content step="2">
        <Vehicle />
      </v-stepper-content>

       <v-stepper-step
        :complete="riskStep > 3"
        :editable="riskStep > 2"
        step="3"
      >
        Informations Conducteurs
      </v-stepper-step>

      <v-stepper-content step="3">
        <Drivers />
      </v-stepper-content>
      
      
    </v-stepper>
  </div>
</template>

<style  scoped>
</style>

<script>

import CG from "./autoSteps/CG.vue";
import Vehicle from "./autoSteps/Vehicle.vue";
import Drivers from "./autoSteps/Drivers.vue";



export default {
  name: "RiskAuto",
  components: {  CG, Vehicle, Drivers},
  props: {
    subscriber: {
      type: Object,
      //default for test purpose
      default: () => {},
    },
  },
  data: () => ({

    risk: {
      disclaimer: false,
      vehicle: {},
      vehicleInfos: {
        firstUseDate: '',
        acquisitionDate: '',
        used: false,
        trailer: {},
      },
      drivers: [
        /* {
          data: {},
          infos: {},
          claims: [],
        }, */
      ],
      options: {
        brokerFees: "0",
        coeffReduc: "0",
      }
    },
  }),
  created() {
    this.$store.commit('estimation/setRisk', Object.assign({},this.risk));
  },
  mounted() {
    
  },
  computed: {
    riskStep: {
      get: function () {return this.$store.state.estimation.riskStep},
      set: function (v) { this.$store.commit('estimation/updRiskStep', v)},
    }
  },
  methods: {

  },
  watch: {
    /* riskStep: async function (val) {
      switch (val) {
        // CG
        case 1:
          break;
        // Vehicle
        case 2:
          this.getVehicleItemsLists();
          break;
        // Driver
        case 3:
          this.getDriverItemsLists();
          break;

        default:
          break;
      }
    }, */
  },
};
</script>









 