<template>
  <v-stepper v-model="estimationStep">
    <v-stepper-header>
      <v-stepper-step
        :complete="estimationStep > 1"
        step="1"
        :editable="estimationStep >= 2 && estimate.id == '-1'"
      >
        Sociétaire
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="estimationStep > 2"
        step="2"
        :editable="estimationStep == 3 && estimate.id == '-1'"
      >
        Risque
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" :complete="estimate.id != '-1'">
        Devis
      </v-stepper-step>
    </v-stepper-header>

    <div id="loading-wrapper" v-show="loading">
      <div id="loading-text">LOADING</div>
      <!--       <v-img
            id="loading-img"
            lazy-src=""
            max-height="48"
            max-width="48"
            :src="`/build/${$appName}/images/logo_transparent.png`"
          ></v-img> -->
      <div id="loading-content"></div>
    </div>
    <v-stepper-items v-show="!loading && $store.state.estimation.hasBroker">
      <v-stepper-content step="1">
        <ClientAddUpd class="pt-5" :isEstimate="true" />
      </v-stepper-content>

      <v-stepper-content step="2">
        <Risk v-if="productCode == $codeGav" class="pt-5" />
        <RiskAuto v-else-if="productCode == $codeAuto" />
      </v-stepper-content>

      <v-stepper-content step="3">
        <Pricing class="pt-5" />
      </v-stepper-content>
    </v-stepper-items>
    <div v-show="!$store.state.estimation.hasBroker">
      <p class="ma-5">Merci de lier votre compte à un courtier afin de tarifer.</p>
    </div>
  </v-stepper>
</template>

<style scoped>
#loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #f00;
}

#loading-content:after {
  content: "";
  position: absolute;
  border: 3px solid #0f0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

#loading-content:before {
  content: "";
  position: absolute;
  border: 3px solid #00f;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

#loading-content {
  border: 3px solid transparent;
  border-top-color: #4d658d;
  border-bottom-color: #4d658d;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #d4cc6a;
  border-bottom-color: #d4cc6a;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}

#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #84417c;
  border-bottom-color: #84417c;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<script>
  import ClientAddUpd from "../client/ClientAddUpd.vue";
  import Risk from "./Risk.vue";
  import RiskAuto from "./RiskAuto.vue";
  import Pricing from "./Pricing.vue";
  import estimationStoreModule from "../../plugins/vuex/estimation/estimationStoreModule";
  import { mapState } from "vuex";

  export default {
    name: "EstimateWorkflow",
    components: {
      ClientAddUpd,
      Risk,
      Pricing,
      RiskAuto,
    },
    data: () => ({}),
    async beforeCreate() {
      this.$store.registerModule("estimation", estimationStoreModule);
    },
    beforeDestroy() {
      this.$store.unregisterModule("estimation");
    },
    async created() {
      logger.info('Création du parcours de tarification')
      logger.debug('Création de la vue Estimation')
      await this.$store.dispatch(
        "estimation/getEstimateModel",
        this.$route.params.productId
      );
    },
    computed: {
      ...mapState({
        step: (state) => state.estimation.estimationStep,
        loading: (state) => state.estimation.loader["getEstimateModel"],
        productCode: (state) => state.estimation.estimate.product.code,
        estimate: (state) => state.estimation.estimate,
      }),
      estimationStep: {
        get: function () {
          return this.step;
        },
        set: function (v) {
          this.$store.commit("estimation/updEstimationStep", v);
        },
      },
    },
  };
</script>
